
.section {

  &.articles-section {
    ul {
      li {
        font-weight: 400;
        font-size: 22px;
        line-height: 1.4;
        padding-bottom: 1rem;

        @media (max-width: 992px) {
          font-size: 14px;
        }
      }
    }
  }
}