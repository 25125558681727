@import "./dyslexic.scss";
@import "./visually-impaired.scss";

body#homepage {
    display: none;
}

.accessibility-functions {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;

    .function {
        display: inline-block;
        text-align: center;
        position: relative;
        margin-left: 5px;
        padding: 6px 12px;
        font-size: 28px;

        .label {
            position: absolute;
            height: 0;
            width: 54px;
            overflow: hidden;
            font-size: 14px;
            line-height: 1.2;
            left: 0;
            @include transition(all .3s ease-in);
        }

        &:hover,
        &.active {
            background: #000;
            cursor: pointer;

            .label {
                height: 50px;
                margin-top: 10px;
            }

            .controls {
                height: 44px;
            }
        }

        &:hover {
            .label {
                height: 50px;
                margin-top: 10px;
            }
        }

        .controls {
            position: absolute;
            font-size: 16px;
            margin-top: 25px;
            height: 0;
            overflow: hidden;
            @include transition(all .3s ease-in);

            i {
                &:hover {
                    cursor: pointer;
                    color: #FFF;
                }
            }
        }
    }
}