.about-us-section {
    .header-slider {
        margin-bottom: 2rem;

        .header-slider-item {
            background-size: contain;
            height: unset;

            .container {
                @media(max-width: 360px) {
                    height: 164px;
                }

                @media(max-width: 400px) {
                    height: 184px;
                }

                @media(max-width: 440px) {
                    height: 204px;
                }

                @media(max-width: 480px) {
                    height: 224px;
                }

                @media(max-width: 500px) {
                    height: 234px;
                }

                @media(max-width: 577px) {
                    height: 269px;
                }

                @media(min-width: 578px) {
                    height: 269px;
                }

                @media(min-width: 768px) {
                    height: 358px;
                }

                @media(min-width: 992px) {
                    height: 477px;
                }

                @media(min-width: 1200px) {
                    height: 567px;
                }
            }
        }
    }

    .container-fluid {
        .header-slider-item {
            .container {
                @media(max-width: 299px) {
                    height: 140px;
                }

                @media(min-width: 300px) {
                    height: 144px;
                }

                @media(min-width: 500px) {
                    height: 244px;
                }

                @media(min-width: 700px) {
                    height: 343px;
                }

                @media(min-width: 900px) {
                    height: 443px;
                }

                @media(min-width: 1100px) {
                    height: 542px;
                }

                @media(min-width: 1300px) {
                    height: 691px;
                }

                @media(min-width: 1500px) {
                    height: 741px;
                }

                @media(min-width: 1700px) {
                    height: 841px
                }

                @media (min-width: 1800px) {
                    height: 947px;
                }
            }
        }
    }

    .container {
        .header-slider-item {
            .container {
                @media(min-width: 578px) {
                    height: 269px;
                }

                @media(min-width: 768px) {
                    height: 358px;
                }

                @media(min-width: 992px) {
                    height: 477px;
                }

                @media(min-width: 1200px) {
                    height: 567px;
                }
            }
        }
    }
}

.about-us-section {
    .container {
        position: relative;
    }
}