body.dyslexia {
    font-family: 'OpenDyslexicMono', sans-serif !important;
    font-weight: 400;

    p,
    .section .section-title,
    .section .section-text ol li {
        font-family: 'OpenDyslexicMono', sans-serif !important;
        font-weight: 400;
    }
}