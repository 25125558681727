/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

/* FONTS */
$fonts: (
    Montserrat: ('Barlow Condensed', sans-serif),
    OpenSans: ('Open Sans', sans-serif),
    main: ('Barlow Condensed', sans-serif),
    sub: ('Open Sans', sans-serif),
);

@font-face {
    font-family: 'OpenDyslexicMono';
    src: url('../../../fonts/OpenDyslexicMono-Regular.eot');
    src: url('../../../fonts/OpenDyslexicMono-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../../fonts/OpenDyslexicMono-Regular.woff2') format('woff2'),
    url('../../../fonts/OpenDyslexicMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@function font($key) {
    @if map-has-key($fonts, $key) {
        @return map-get($fonts, $key);
    }

    @warn "Unknown `#{$key}` in $fonts.";
    @return null;
}