.image-container {
    position: relative;

    .image {
        // cursor: pointer;
        opacity: .1;
        transition: opacity 2s;

        @media (max-width: 992px) {
            text-align: center;
        }

        img {
            // max-height: 700px;
            // max-width: unset;

            @media (max-width: 992px) {
                max-width: 70%;
            }
        }

        &.image-hover {
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 1s;
        }
    }

    &.text-right {
        .image-hover {
            right: 0;
        }
    }

    &:hover {
        .image-hover {
            opacity: 1;
        }
    }
}

.homepage-contact-section {
    h2 {
        font-family: "Barlow Condensed", sans-serif;
        font-size: 46px;
        font-weight: 600;
        color: #FFF;
        text-transform: uppercase;
        margin-bottom: 60px;

        @media (max-width: 992px) {
            font-size: 16px;
            margin-bottom: 20px;
        }

        @media (max-width: 746px) {
            text-align: center;
        }
    }

    ul {
        li {
            margin-bottom: 30px;

            @media (max-width: 992px) {
                margin-bottom: 10px;
            }

            @media (max-width: 746px) {
                text-align: center;
            }

            a {
                font-size: 22px;

                @media (max-width: 992px) {
                    font-size: 14px;
                }
            }
        }
    }

    .homepage-form-wrapper {
        font-size: 20px;

        @media (max-width: 992px) {
            max-width: 90%;
        }

        input,
        textarea {
            font-size: 20px;
            border-radius: 0;
            border: 1px solid #7b7b7b;
            color: #7b7b7b;
            background: transparent;

            @media (max-width: 992px) {
                font-size: 14px;
            }
        }

        textarea {
            height: 250px;

            @media (max-width: 992px) {
                height: 150px;
            }

            @media (max-width: 425px) {
                height: 70px;
            }
        }

        button {
            font-size: 20px;
            border: 1px solid #709300;
            color: #709300;
            background: transparent;
            border-radius: 0;
            padding: 0.5rem 2rem;
            float: right;
            transition: all 0.5s;

            @media (max-width: 992px) {
                font-size: 14px;
                padding: 0.2rem 1rem;
            }

            &:hover {
                color: #0b0b0b;
                background: #709300;
            }
        }
    }
}

.homepage-links-section {
    .container {
        @media(max-width: 992px) {
            text-align: center;
        }

        ul {
            li {
                margin-bottom: 0;
                color: #fcb80f;
                text-transform: uppercase;
                font-size: 28px;
                font-weight: 600;

                @media (max-width: 992px) {
                    font-size: 14px;
                }
            }
        }
    }
}