body.visually-impaired {
    background: rgba(0, 0, 0, .9);

    a {
        color: #FFF;
        text-decoration: underline;
    }

    .section .section-text {
        color: #FFF;

        a {
            color: #FFF;
            text-decoration: underline;
        }
    }
}