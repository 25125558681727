.section {
    .page-section {}
}

#fp-nav {
    top: 0;
    transform: none;
    z-index: 9999;

    ul {
        li {
            text-align: right;
            margin: 0;

            &:hover {
                a {
                    &.active {
                        span {
                            border-radius: 0;
                            margin: 0;
                            width: 4px;
                            height: auto;
                        }
                    }

                    span {
                        border-radius: 0;
                        margin: 0;
                        width: 2px;
                    }
                }
            }

            a {
                display: inline-block;
                width: auto;

                span {
                    top: 0;
                    background: #282828;
                    border-radius: 0;
                    margin: 0;
                    width: 2px;

                    &.count {
                        width: auto;
                        height: auto;
                        position: absolute;
                        left: -20px;
                        background: transparent !important;
                        bottom: 30%;
                        top: unset;
                    }
                }

                &.active {
                    span {
                        background: #9c9c9b;
                        border-radius: 0;
                        margin: 0;
                        width: 4px;
                    }
                }
            }
        }
    }
}