
.section {

  &.videos-section {
    h2 {
      text-align: start;
      font-family: font(main);
      font-weight: 600;
      font-size: 28px;
      color: color('text', 'light');
      text-transform: uppercase;

        @media (max-width: 992px) {
          font-size: 12px;
        }
      }


  }

}

