.section.brochures-section {

    .image-wrapper {
      text-align: center;
      padding-bottom: 40px;

      @media(max-width: 992px) {
        padding-bottom: 20px;
      }
    }

    h3 {
      font-size: 22px;
      padding: 10px 0;

      @media(max-width: 992px) {
        font-size: 14px;
      }
    }


 }