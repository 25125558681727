// .navbar-light {

//     .nav-link {
//         font-size: 1.5rem;
//         color: rgba(0, 0, 0, 1) !important;

//         &.btn-gradient {
//             color: #fff !important;

//             &:hover {
//                 color: #fff !important;
//             }
//         }

//         &:hover {
//             color: color('primary') !important;
//         }
//     }
// }

// @media (max-width: $media_xs) {
//     .navbar-light {
//         .navbar-brand {
//             max-width: 70%;
//             display: inline-block;

//             .navbar-brand-image {
//                 max-width: 100%;
//             }
//         }
//     }
// }

// @media (max-width: $media_lg) {
//     .mobile-nav {
//         flex-basis: 100%;
//         flex-grow: 1;
//         align-items: center;

//         .list-inline-item {
//             a {
//                 display: block;
//                 padding: 0.5rem 2rem;
//                 text-align: center;

//                 &:hover {
//                     text-decoration: none;
//                     color: color('primary');
//                 }
//             }
//         }
//     }
// }


/* HEADER STYLES */
$margin_y: 20px;

.header-container {
    width: 100%;
    max-width: 100vw;

    color: color('nav', 'text', 'main');

    height: auto;
    //overflow-x: hidden;
    background: color('nav', 'background', 'main');
    border-bottom: 1px solid color('primary');

    &.fixed-top {
        backface-visibility: hidden;
    }

    // firefox scrollbar
    // overflow-y: scroll;
    // scrollbar-color: rgba(color('primary'), 0.7) transparent;
    // scrollbar-width: thin;

    // //internet explorer scrollbar
    // scrollbar-face-color: color('primary');
    // scrollbar-arrow-color: color('primary');
    // scrollbar-track-color: transparent;
    // scrollbar-shadow-color: rgb(0, 0, 0);
    // scrollbar-highlight-color: rgb(0, 0, 0);
    // scrollbar-3dlight-color: color('primary');
    // scrollbar-darkshadow-Color: transparent;

    // // webkit browsers (chrome...) scrollbar
    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(146, 192, 31, 0.3);
    //     background-color: transparent;
    // }

    // &::-webkit-scrollbar {
    //     width: 5px;
    //     background-color: transparent;
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: rgba(color('primary'), 0.7);
    // }

    // &.scrolled {}

    .header-nav-top {
        background: color('nav', 'background', 'accent');
        border-bottom: 1px solid color('border', 'light');
        // max-height: 30px;
    }

    .header-nav-main {
        max-height: 80px;
        height: 80px;

        /* Header logo */
        .company-brand {
            text-align: left;
        }

        img {
            padding-top: $margin_y;
        }

        .header-nav {
            position: relative;
            bottom: 0;
            font-size: 14px;
            line-height: 80px;
            font-family: font(sub);
            font-weight: 700;
            margin-bottom: 0;

            .nav-item {

                &:hover,
                &.active {
                    border-bottom: 3px solid color('primary');
                }

                position: relative;

                &:hover {
                    .dropdown-menu {
                        display: block;
                        position: absolute;
                        top: 73px;
                        left: -1px;
                    }
                }

                .dropdown-menu {
                    z-index: 1030;
                    line-height: 1.5;
                    background: color('nav', 'background', 'accent');
                    color: color('nav', 'text', 'main');
                    @include border-radius(0);

                    li {
                        &:not(:last-of-type) {
                            border-bottom: 1px solid color('primary');
                        }

                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    .dropdown-item {

                        &.active,
                        &:hover {
                            background: color('primary');
                            color: color('white');
                        }

                        font-size: 14px;
                        font-family: font(main);
                        text-transform: uppercase;


                    }
                }

                .nav-link {
                    padding: 0 10px;
                    line-height: 75px;

                }
            }
        }
    }

}

/* Header social icons */
.social-container {
    position: relative !important;
    padding: 0;

    .social-popup,
    .social-popup-trigger {
        display: none;
    }

    .social-link {
        color: color('nav', 'text', 'accent');
        text-align: center;
        padding-left: 0;
        border: none;
        line-height: 40px;

        &.icon-only {
            font-size: 15px;
            padding-right: 15px;
            line-height: 40px;
            display: inline-block;

            i {
                font-size: 13px;
            }

            i.fa-facebook {
                font-size: 15px;
            }

            i.fa-google-plus {
                font-size: 15px;
            }
        }

        &:not(:first-of-type) {
            padding-left: 15px;
        }

        &:not(:last-of-type) {
            border-right: 1px solid color('border', 'light') !important;
        }

        &:first-of-type {
            // padding-left: 25px;
            padding-right: 15px;
        }

        i {
            vertical-align: middle;
            color: color('primary');
            line-height: 30px;
        }

    }
}

/* HEADER CONTACT */
.contact-info {
    text-align: right;
    display: inline-block;

    a {
        line-height: 40px;
        font-family: font(sub);
        display: inline-block;
        border-left: 1px solid color('border', 'light');
        color: color('nav', 'text', 'accent');
        padding: 0 10px;
        font-size: 13px;
        padding-left: 15px;

        &:hover {
            text-decoration: none;
        }
    }

    i {
        color: color('primary');
        line-height: 30px;
        padding-right: 5px;

        &.fa-phone {
            font-size: 14px;
        }

        &.fa-skype {
            font-size: 1.4rem;
        }

        &.fa-envelope {
            font-size: 14px;
        }
    }

    .nav-contact-text {
        display: none;

        @media (min-width: 1600px) {

            display: inline;
        }
    }

    &.text-muted {
        display: inline;
        margin-left: 1px;


    }

    label {
        display: inline;
        margin: 0;
    }
}

.lang-wrap {
    margin-right: 5px;
    position: relative;
    top: 5px;

    .dropdown-menu {
        top: 20px !important;
    }

    a {
        line-height: 30px;
        text-decoration: none;
        text-transform: capitalize;
        font-size: 13px;
        font-family: font(sub);
        font-weight: 500;
        color: color('nav', 'text', 'main');

        &.dropdown-toggle::after {
            border: 0 !important;
            content: "\f078" !important;

            vertical-align: 0 !important;
            margin-left: 5px !important;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 10px;

        }

        .lang-label {
            @media(max-width: $media_md) {
                display: none;
            }
        }

        img {
            width: 21px;
            margin-right: 10px;
        }

        @include transition(all .3 linear);

        &.active {
            color: color('primary');
        }

        &:hover {
            opacity: 0.7;
        }

        // &:not(:first-of-type) {
        //     padding-left: 10px;

        // }

        // &:not(:last-of-type) {
        //     border-right: 1px solid color(border-light);
        //     padding-right: 10px;
        //     padding-left: 25px;
        // }
    }
}

/* BUTTON HAMBURGER */
.btn-menu {
    margin-left: auto;
    display: inline-block;
    padding: $margin_y+10;
    color: color('text', 'dark');

    &:hover {
        text-decoration: none;
    }

    .menu-text {
        text-transform: uppercase;
        font-size: 1rem;
        font-family: font(sub);
        font-weight: 600;
        line-height: 32px;
        position: relative;
        top: -5px;
        left: 10px;
    }

    span {
        display: block;
        background-color: color('text', 'dark');
        width: 20px;
        height: 2px;
        margin-bottom: 4px;
    }
}

/* MEDIA QUERIES */
@media (max-width: 1700px) {
    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .header-container {
        .social-container {
            .social-link {
                width: 40px;
                line-height: 30px;
            }
        }
    }

    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .contact-info {

        //text-align: left;
        span {
            label {
                display: none;
            }
        }
    }
}

@media(max-width: 1040px) {
    .contact-info {
        span {
            padding: 0 4px;
            border: 0 !important;
            font-size: 1.1rem;
        }
    }
}

@media (max-width: $media_xl) {
    .contact-info {
        .text-muted {
            display: none;
        }
    }
}

@media (max-width: $media_lg) {


    .btn-menu {
        margin-top: 5px;

        .menu-text {
            display: none !important;
        }
    }

    .mobile-lang {
        font-size: 1rem;
        height: 38px;
    }

    .mobile-book {
        position: relative;
        top: 45px;
        font-size: 1.2rem;
        background-color: color('primary');

        i {
            color: color('white');
        }
    }
}