@import './_config/scss/config.scss';
@import './global.scss';
/* Layout imports */
@import './layout/layout.scss';
/* Layout imports end */
/* Modules import */
@import './modules/modules.scss';
/* Modules impor end */
/* Views Import */
@import './page_categories/page_categories.scss';
/* Views Import End */
/* COMPONENTS */
@import './components/componenets.scss';
/* COMPONENETS END */
/* Utilities import */
@import './utilities/utilities.scss';
/* Utilities import end */

@import './FeatureWidget/FeatureWidget.scss';

.site-logo {
    background-image: url('../images/brand/logo.png');
    background-repeat: no-repeat;
    background-position: center;
    // width: 217px;
    width: 100%;
    height: 23px;
    display: inline-block;
    margin-bottom: 60px;

    &.home-logo {
        background-image: url('../images/brand/logo-home.png');
        height: 136px;
    }

    @media (max-width: 992px) {
        margin-bottom: 20px;
        background-position: center !important;
    }

    &.align-left {
        background-position: left;
    }

    &.align-right {
        background-position: right;
    }
}

.scroll-button {
    background-image: url('../images/icons/scroll-button.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 66px;
    width: 52px;
    display: block;
    position: absolute;
    bottom: 0;
    // left: 10%;
    left: 15px;

    @media (max-width: 992px) {
        transform: scale(0.7);
        transform-origin: bottom left;
    }
}

.inquiry-button {
    background-image: url('../images/icons/inquire-button.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 100px;
    display: block;
    position: absolute;
    bottom: 20px;
    // right: 10%;
    right: 15px;

    @media (max-width: 992px) {
        transform: scale(0.7);
        transform-origin: bottom left;
    }
}

body.fp-viewing-9 {
    .inquiry-button {
        height: 0;
    }
}

@media (max-width: 768px) {
    #fp-nav.fp-right {
        right: 10px;

        @media (max-width: 425px) {
            right: 2px;
        }

        ul {
            li {
                a {
                    span.count {
                        font-size: 14px;

                        @media (max-width: 425px) {
                            left: -17px;
                        }
                    }
                }
            }
        }
    }
}