@import './banners/banners.scss';
@import './carousel/carousel.scss';
@import './documents/documents.scss';
@import './google_maps/google_maps.scss';
@import './grid/grid.scss';
@import './header_slider/header_slider.scss';
@import './home_carousel/home_carousel.scss';
@import './list/list.scss';
// @import './occupation_calendar/occupation_calendar.scss';
@import './slider/slider.scss';
@import './sliderV1/sliderV1.scss';
@import './sliderV2/sliderV2.scss';
@import './sliderV3/sliderV3.scss';
@import './sliderV4/sliderV4.scss';
// //@import './social_share_inner/social_share_inner.scss';
// //@import './social_share/social_share.scss';
@import './twoColBulletList/twoColBulletList.scss';

.simplePopupOverlay_0.cookie-overlay:not(.height) {
    height: 100vh !important;
}

@media (max-width: 992px) {
    .simplePopupOverlay_0.cookie-overlay {
        overflow: scroll;
    }
}